var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "updateAndroid" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "text" },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v("<<返回")]
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("TitleModule", { attrs: { title: _vm.title } }),
          _c(
            "div",
            { staticClass: "searchWrapper" },
            [
              _c(
                "div",
                { staticClass: "disabledAddListWrapper" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.formInline.channelList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "上传安装包",
                          align: "center",
                          width: "320",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !scope.row.downloadUrl
                                  ? _c(
                                      "el-upload",
                                      {
                                        ref: "upload" + scope.$index,
                                        staticClass: "upload",
                                        attrs: {
                                          disabled: !_vm.isEdit,
                                          action:
                                            "/acb/2.0/application/version/upload",
                                          headers: _vm.uploadHeader,
                                          accept: "apk",
                                          name: "file",
                                          data: {
                                            applicationId:
                                              _vm.$route.query.applicationId,
                                          },
                                          enctype: "multipart/form-data",
                                          "on-change": _vm.fileChange,
                                          "auto-upload": false,
                                          "show-file-list": false,
                                          "on-success": _vm.handleAvatarSuccess,
                                          "before-upload":
                                            _vm.beforeAvatarUpload,
                                          "on-progress": _vm.onError,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "text",
                                              loading:
                                                _vm.uploadIndex ===
                                                  scope.$index &&
                                                _vm.tagUp == 1,
                                              disabled: !_vm.isEdit,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onUpLoad(
                                                  $event,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(" 上传安装包 "),
                                            _c("br"),
                                            _vm._v(
                                              "(支持APK文件，最大不超过200MB) "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.downloadUrl,
                                        expression: "scope.row.downloadUrl",
                                      },
                                    ],
                                    staticClass: "uploadText",
                                  },
                                  [
                                    _c("label", [_vm._v("包名：")]),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.packageName)),
                                    ]),
                                    _c("label", [_vm._v("大小：")]),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.fileSize)),
                                    ]),
                                    _c("br"),
                                    _c("label", [_vm._v("版本号：")]),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.versionName)),
                                    ]),
                                    _c("label", [_vm._v("Versioncode：")]),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.versionCode)),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "渠道名称",
                          align: "center",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: scope.row.channelName,
                                        expression: "scope.row.channelName",
                                      },
                                    ],
                                    staticStyle: { width: "140px" },
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        _vm.formInline.upgradeStatus === 1 ||
                                        !_vm.isEdit,
                                      maxlength: "100",
                                    },
                                    domProps: { value: scope.row.channelName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          scope.row,
                                          "channelName",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "下载地址", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.downloadUrl || "")),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.downloadUrl
                                  ? _c(
                                      "el-upload",
                                      {
                                        ref: "upload" + scope.$index,
                                        attrs: {
                                          disabled: !_vm.isEdit,
                                          action:
                                            "/acb/2.0/application/version/upload",
                                          headers: _vm.uploadHeader,
                                          accept: "apk",
                                          name: "file",
                                          data: {
                                            applicationId:
                                              _vm.$route.query.applicationId,
                                          },
                                          enctype: "multipart/form-data",
                                          "on-change": _vm.fileChange,
                                          "auto-upload": false,
                                          "show-file-list": false,
                                          "on-success": _vm.handleAvatarSuccess,
                                          "before-upload":
                                            _vm.beforeAvatarUpload,
                                          "on-progress": _vm.onError2,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "text",
                                              loading:
                                                _vm.uploadIndex ===
                                                  scope.$index &&
                                                _vm.tagUp2 == 1,
                                              disabled:
                                                _vm.formInline.upgradeStatus ===
                                                  1 || !_vm.isEdit,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onUpLoad(
                                                  $event,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 重新上传 ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("span"),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      loading: _vm.removeIndex === scope.$index,
                                      type: "text",
                                      disabled:
                                        _vm.formInline.upgradeStatus === 1 ||
                                        !_vm.isEdit,
                                      size: "small",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteRow(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        type: "success",
                        disabled:
                          _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addRow.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("继续" + _vm._s(_vm.$t("button.addto")))]
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { width: "600px" },
                  attrs: {
                    "label-position": "right",
                    disabled: !_vm.isEdit,
                    "label-width": "120px",
                    model: _vm.formInline,
                    rules: _vm.rules,
                  },
                },
                [
                  _c("h2", [_vm._v("基础配置")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "升级方式", prop: "updateType" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.formInline.updateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "updateType", $$v)
                            },
                            expression: "formInline.updateType",
                          },
                        },
                        [_vm._v("推荐升级")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formInline.updateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "updateType", $$v)
                            },
                            expression: "formInline.updateType",
                          },
                        },
                        [_vm._v("强制升级")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.formInline.updateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "updateType", $$v)
                            },
                            expression: "formInline.updateType",
                          },
                        },
                        [_vm._v("手动升级")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "更新说明", prop: "updateDesc" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入更新说明",
                          maxlength: "200",
                          autosize: { minRows: 4, maxRows: 8 },
                        },
                        model: {
                          value: _vm.formInline.updateDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "updateDesc", $$v)
                          },
                          expression: "formInline.updateDesc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h2", [_vm._v("高级配置")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "升级时间", prop: "upgradeType" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled:
                              _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                            label: 1,
                          },
                          model: {
                            value: _vm.formInline.upgradeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "upgradeType", $$v)
                            },
                            expression: "formInline.upgradeType",
                          },
                        },
                        [_vm._v("立即升级")]
                      ),
                      _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-right": "20px" },
                          attrs: {
                            disabled:
                              _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                            label: 2,
                          },
                          model: {
                            value: _vm.formInline.upgradeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "upgradeType", $$v)
                            },
                            expression: "formInline.upgradeType",
                          },
                        },
                        [_vm._v("定时升级")]
                      ),
                      _c("el-date-picker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formInline.upgradeType !== 1,
                            expression: "formInline.upgradeType !== 1",
                          },
                        ],
                        attrs: {
                          disabled:
                            _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                          clearable: false,
                          editable: false,
                          "picker-options": _vm.pickerOptions,
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间",
                        },
                        on: { change: _vm.upgradeTimeSelect },
                        model: {
                          value: _vm.formInline.upgradeTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "upgradeTime", $$v)
                          },
                          expression: "formInline.upgradeTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "20px" },
                },
                [
                  _vm.isEdit
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "88px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.submitData },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "88px" },
                      attrs: { type: "info" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.isEdit ? "取消" : "返回"))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }